import { API_METHOD, ENDPOINTS } from '../appConsts';
import { handleFetch } from './handleFetch';
const apiRoot = ENDPOINTS.API + '/order';



const buildingInit = {
    "buildingId": 0,
    "customerId": 0,
    "agentId": 0,
    "agentLastFirst": "",
    "superName": "",
    "superEmail": "",
    "superPhone1": "",
    "superPhone2": "",
    "superPhone3": "",
    "superPhone10Digits": "",
    "superPhoneFormatted": "",
    "buildingName": "",
    "aka": "",
    "address": "",
    "cityId": 0,
    "city": "",
    "stateId": 0,
    "state": "",
    "zipId": 0,
    "zip": "",
    "boroughId": 0,
    "borough": "",
    "hoodId": 0,
    "hood": "",
    "block": "",
    "lot": "",
    "statusId": 1,
    "status": "",
    "createdBy": "",
    "dateCreated": "",
    "taxExempt": 0,
    "coiReceived": 0,
    "coiExpDate": ""
};

export { buildingInit };


export const submitOrder = (data) => {

    let url = `${apiRoot}/InsertOrder`;

    let payload = {
        "recordType": data.recordType,
        "agentFirstName": data.agentFirstName,
        "agentLastName": data.agentLastName,
        "agentEmail": data.agentEmail,
        "agentPhone10Digits": data.agentPhone10Digits,
        "contactFirstName": data.contactFirstName,
        "contactLastName": data.contactLastName,
        "contactPhone10Digits": data.contactPhone10Digits,
        "contactEmail": data.contactEmail,
        "customerName": data.customerName,
        "isNewCustomer": data.isNewCustomer,
        "customerAddress": data.customerAddress,
        "customerCity": data.customerCity,
        "customerState": data.customerCity,
        "customerZip": data.customerZip,
        "customerTaxId": "test",
        "isNewCustomer": false,
        "shippingStreet1": data.shippingStreet1,
        "shippingCity": data.shippingCity,
        "shippingState": data.shippingState,
        "shippingZip": data.shippingZip,
        "notes": data.notes,
        "details": data.details,
        "installedApps": data.installedApps,
        "installedAppsNotes": data.installedAppsNotes,
        "site": 'VAR'
    };

    const options = {
        method: API_METHOD.POST,
        body: JSON.stringify(payload)
    };

    return handleFetch(url, options, true);
};



// export const search = (data) => {
//     let url = `${apiRoot}/Search`;

//     const options = {
//         method: API_METHOD.POST,
//         body: JSON.stringify(data)
//     };
//     return handleFetch(url, options, true);
// };

// export const getAllByCustomerId = (customerId) => {
//     let url = `${apiRoot}/GetAllByCustomerId/${customerId}`;

//     const options = {
//         method: API_METHOD.POST
//     };
//     return handleFetch(url, options, true);
// };

// export const getSingle = (id) => {

//     let url = `${apiRoot}/GetSingle/${id}`;

//     const options = {
//         method: API_METHOD.GET
//     };

//     try {
//         return handleFetch(url, options, true);
//     } catch (e) {
//         debugger;
//     }
// };
// export const update = (data) => {
//     let url = `${apiRoot}/Update`;

//     const options = {
//         method: API_METHOD.POST,
//         body: JSON.stringify(data)
//     };
//     return handleFetch(url, options, true);
// };



// export const verifyNewBuilding = (address) => {
//     let url = `${apiRoot}/VerifyNewBuilding`;

//     let payload = {
//         address: address
//     };

//     const options = {
//         method: API_METHOD.POST,
//         body: JSON.stringify(payload)
//     };
//     return handleFetch(url, options, true);
// };